export const platformConfig = {
  apiKey: "dontHave",
  apiUrl: "http://danzuck.com/api/v1",
  graphUrl: "http://danzuck.com/graphql",
  production: true
};

export const productionHosts = [
  "hangintooth.com",
  "www.hangintooth.com",
  "danzuck.com",
  "www.danzuck.com",
];