import React, { ChangeEventHandler, useState } from 'react';
// import { OAuth2Client } from 'google-auth-library';

// const googleClient = new OAuth2Client({
  // clientId: `${process.env.GOOGLE_CLIENT_ID}`,
// });

const Account = () => {
  return (
    <>
    <h1>Hi there</h1>

    <span id="g_id_onload"
      data-client_id=""
      data-context="signin"
      data-ux_mode="popup"
      data-login_uri=""
      data-auto_prompt="true">
    </span>

    <span className="g_id_signin"
     data-type="code token"
     data-shape="rectangular"
     data-theme="outline"
     data-text="signin_with"
     data-size="large"
     data-logo_alignment="left">
    </span>

    </>

  )
}
export default Account;